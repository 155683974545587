
import {Component, Vue} from 'nuxt-property-decorator'
import BlockName from '~/components/BlockName.vue'

@Component({components: {BlockName}})
export default class extends Vue {
  items: any[] = []
  expanded: any = {}

  async fetch() {
    await this.init()
  }

  init() {
    try {
      return this.$axios.get('/widget/popular-models').then((response: any) => {
        this.items = response.data
        return response.data
      })
    } catch(e) {
      console.error(e);
    }
  }

  getLink(manufacturer: string, model?: string, typeCar?: number) {
    const params: any = {manufacturer}
    if (model) params.model = `model-${model}`
    if (typeCar && `${typeCar}` === '1') {
      return this.localePath({name: 'catalog.trucks', params})
    }
    return this.localePath({name: 'catalog.cars', params})
  }
}
