
import {Component, Vue} from 'nuxt-property-decorator'
import PopularItem from '../ProductItem.vue'
import CarouselList from '../CarouselList.vue'
@Component({
  components: { PopularItem, CarouselList }
})
export default class extends Vue {
  get title() {
    return this.$t('component.top.title');
  }
}
