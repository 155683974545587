
import { Component, Vue, Ref } from 'nuxt-property-decorator'
import Stock from '~/components/StockAct.vue'
import PaymentsBlock from '~/components/main/PaymentsBlock.vue'
import CategoryList from '~/components/categories/List.vue'
import CInfoBlock from '~/components/info-block/List.vue'
import BlockName from '~/components/BlockName.vue'
import CPopularBlock from '~/components/popular/List.vue'
import CatalogMenu from '~/components/catalog-menu/List.vue'
import Cars from '~/components/car-selector/cars.vue'
import BrandList from '~/components/BrandList.vue'

@Component({
  components: { Stock, PaymentsBlock, CategoryList, CInfoBlock, BlockName, BrandList, CPopularBlock, CatalogMenu, Cars }
})
export default class extends Vue {
  @Ref('categoryList')
  categoryList!: CategoryList;

  onChangeCarSelected() {
    this.categoryList.refresh()
  }
}
