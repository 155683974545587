
import { Component, Vue } from 'nuxt-property-decorator'
import ItemCars from './items/cars.vue'
import ItemTrucks from './items/trucks.vue'
import ItemAkum from './items/akum.vue'
import ItemTires from './items/tires.vue'
import ItemOptics from './items/optics.vue'
import ItemOils from './items/oils.vue'
@Component({
  components: { ItemCars, ItemAkum, ItemTires, ItemOptics, ItemOils, ItemTrucks }
})
export default class extends Vue {
}
