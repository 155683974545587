
import { Component, Vue } from 'nuxt-property-decorator'
import {CarSelectorStore} from "~/components/car-selector/store";
@Component
export default class extends Vue {
  route: any = {
    name: 'catalog.trucks_not_brand',
    params: {}
  }

  get imgAlt() {
    return this.$t('component.catalog.menu.items.kuzov').toString() + " " + this.$t('component.catalog.menu.items.detail').toString()
  }

  created() {
    this.route.name = this.$localizeRoute('catalog.trucks_not_brand')
  }

  mounted() {
    if (this.$isServer) return;
    const carSelected = CarSelectorStore.getCarSelected()
    const typeCar = CarSelectorStore.getCarSelectedType()
    if (!typeCar || typeCar !== 'trucks') return
    if (carSelected) {
      this.route.name = this.$localizeRoute('catalog.trucks')
      for (const f of carSelected.data) {
        switch (f.key) {
          case 'brand':
            this.route.params.manufacturer = f.data.base.manufacturer_name_translit
            break
          case 'model':
            this.route.params.model = `model-${f.data.base.model_name_translit}`
            break
          case 'type':
            this.route.params.modifications = `modifications-${f.data.base.slug}`
            break
        }
      }
    }
  }
}
