
import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'
import BlockName from '~/components/BlockName.vue'
import Product from '~/std/Product'
// @ts-ignore
import BCarouselList from 'buefy/src/components/carousel/CarouselList.vue'
@Component({
  components: { BlockName, BCarouselList }
})
export default class extends Vue {
  @Prop({ default: 'Популярное' }) title!: string
  @Prop({ default: '' }) url!: string
  itemPosition = 0
  itemsToShow = 6
  items: any[] = []
  data(){
    return{
      Breakpoints: {
        0: {
          itemsToShow: 1,
        },
        601: {
          itemsToShow: 3,
        },
        1025: {
          itemsToShow: 5,
        },
        1400: {
          itemsToShow: 6,
        },
      }
    }
  }

  async fetch() {
    await this.load()
  }

  @Watch('$i18n.locale')
  changeLocale() {
    this.load()
  }

  async load() {
    if (!this.url) return
    const response = await this.$axios.get(this.url)
    this.items.splice(0);
    for (const item of response.data) {
      this.items.push(new Product(item))
    }
  }

  prev() {
    if (this.itemPosition > 0) this.itemPosition--
  }

  get isNext() {
    return this.items.length - this.itemsToShow > this.itemPosition
  }

  next() {
    if (this.isNext) this.itemPosition++
  }
}
