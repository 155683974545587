
import { Component, Vue } from 'nuxt-property-decorator'
import {CarSelectorStore} from "~/components/car-selector/store";
@Component
export default class extends Vue {
  route: any = {
    name: 'catalog.cars_not_brand',
    params: {}
  }

  get imgAlt() {
    return this.$t('component.catalog.menu.items.all').toString() + " " + this.$t('component.catalog.menu.items.cars').toString()
  }

  created() {
    this.route.name = this.$localizeRoute('catalog.cars_not_brand')
  }

  mounted() {
    if (this.$isServer) return;
    const carSelected = CarSelectorStore.getCarSelected()
    const typeCar = CarSelectorStore.getCarSelectedType()
    if (!typeCar || typeCar !== 'cars') return
    if (carSelected) {
      this.route.name = this.$localizeRoute('catalog.cars')
      for (const f of carSelected.data) {
        if (f.key === 'brand' && f.data.value) {
            this.route.params.manufacturer = f.data.base.manufacturer_name_translit
        } else if (f.key === 'model' && f.data.value) {
            this.route.params.model = `model-${f.data.base.model_name_translit}`
        } else if (f.key === 'type' && f.data.value) {
            this.route.params.modifications = `modifications-${f.data.base.slug}`
        }
      }
    }
  }
}
